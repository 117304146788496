import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import Cooperation from "../../components/Cooperation";
import HomeInfoBoxes from "../../components/HomeInfoBoxes";
import MediaLogos from "../../components/MediaLogos";

const BauKreditrechner = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Wohnbaukredit – Kredit berechnen mit miracl" showCalc={true} />
            <Cooperation hasGreyBg hasMarginTop />
            <HomeInfoBoxes hasMarginTop />
            <MediaLogos hasGreyBg hasMarginTop />
            <Article hasMarginTop>
                <h2>der miracl Baukreditrechner!</h2>
                <h4>Der erste Schritt zum Kredit - schnell und unverbindlich.</h4>
                <p>
                    Einmal im Leben ein Haus bauen! Davon träumen wahrscheinlich die meisten Menschen. Und genau bei der
                    Erfüllung dieses Traums hilft dir unser Baukreditrechner. Denn bevor der wortwörtliche Grundstein
                    für dein Projekt gelegt wird, musst du dich erst um die richtige Finanzierung kümmern. Egal wie viel
                    Eigenkapital du hast, welche Zinsen du dir wünscht und ob für welche Kreditart du dich entscheidest:
                    Hol dir jetzt eine Ersteinschätzung mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    – oder melde dich direkt bei unseren Finanzierungs-Experten.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Jetzt Beratung anfordern!
                </a>
                <hr />

                <h2>Das kann unser Baukreditrechner</h2>
                <p>
                    Mit unserem Baukreditrechner bekommst du schnell und unkompliziert einen ersten und unverbindlichen
                    Vorschlag für deinen Kredit. Egal um welches Projekt es sich handelt – ob Einfamilienhaus,
                    Mehrparteienhaus, Nebenwohnsitz oder Renovierungsarbeiten – mit unserem Online-Rechner hast du
                    schnell einen Anhaltspunkt für deine Finanzierung.
                </p>
                <p>
                    Zu den Daten die der{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kredit-Rechner
                    </Link>{" "}
                    benötigt gehören der Kreditbetrag, die Laufzeit, die du dir für deinen Kredit wünscht und auch ob du
                    lieber einen fixen oder variable Zinssatz hättest. Diese Angaben reichen – schon erhältst du ein
                    erstes individuelles Angebot. Im nächsten Schritt empfiehlt es sich, mit einem Experten zu sprechen.
                    Und zwar auch dann, wenn du auf den ersten Blick nicht sicher bist, ob du dir den Kredit, den du
                    brauchst, auch wirklich leisten kannst.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Kredit berechnen – Kredite vergleichen</h2>
                <p>
                    Da der Baukreditrechner von miracl dir nur einen guten ersten Eindruck zu deinen Möglichkeiten geben
                    soll, solltest du auf jeden Fall Kontakt mit einem Finanzberater aufnehmen. Denn klarerweise kann
                    der{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noopener">
                        Kreditrechner
                    </Link>{" "}
                    nicht alle tagesaktuellen Kreditangebot der über 100 Kreditgeber in Österreich vergleich.
                </p>
                <p>
                    miracl hingegen schon. Bei miracl arbeiten unabhängige Berater, die jeden Tag aufs neue die
                    unterschiedlichste Kreditraten und Angebot von über 100 Banken in Österreich vergleichen und an
                    Menschen vermitteln, die auf der Suchen nach einem Baukredit sind. Und das beste: Jede*r kann sich
                    kostenlos und unverbindlich an miracl wenden. Wenn du es dir wünscht, kontaktieren wir dich gerne
                    auch telefonisch, um alle Details zu deinem Kredit-Angebot mit dir zu besprechen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kontakt aufnehmen
                    </a>
                </p>
                <p>
                    miracl bietet nicht nur diverse{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noopener">
                        Kredit-Rechner
                    </Link>{" "}
                    online und kostenlos an. Wir beraten dich umfassend zu allen Themen rund um Eigenheim und
                    Immobilienkauf. So kannst du auf der Suche nach deiner Wunschimmobilie gleich mehrfach profitieren:
                </p>
                <ul>
                    <li>miracl bietet einen schnellen und kostenlosen Kreditvergleich</li>
                    <li>miracl bietet tagesaktuelle Vergleiche, mit denen du richtig Geld sparen kannst</li>
                    <li>miracl ist schnell und unabhängig – wir garantieren ein günstiges Angebot</li>
                </ul>
                <hr />

                <h2>In wenigen Schritten zum Baukredit</h2>
                <p>
                    Keine Frage, die Entscheidung für den richtigen Kredit sollte an nicht auf die leichte Schulter
                    nehmen. Aber mit ein bisschen Recherche und der richtigen professionellen Beratung kann die Suche
                    nach der richtigen Finanzierung auch ganz schnell gehen.
                </p>

                <h3>Schritt 1: Vom miracl-Rechner profitieren</h3>
                <p>
                    Nutze unseren Online-Rechner, um für deinen Wunschkredit schnell und einfach die wichtigsten
                    Eckdaten zu erruieren. Lege dazu einfach den Kreditbetrag fest, stelle die für dich ideale Laufzeit
                    ein und wähle dann zwischen einem fixem und einem variablem Zins – schon erhältst du ein erstes
                    Angebot. Schnell, einfach und vor allem kostenlos. Mit diesem ersten Eindruck kannst du dann deine
                    weiteren Möglichkeiten besser beurteilen und mit einem Finanzberater Kontakt aufnehmen.
                </p>

                <h3>Schritt 2: Beratungsgespräch vereinbaren</h3>
                <p>
                    Nimmst du mit unserem Team aus Beratern und Finanzexperten Kontakt auf, profitierst du gleich
                    mehrfach: Wir können dir eine schnell und bereits sehr detaillierte Einschätzung deiner
                    Kredit-Möglichkeiten bieten. Meistens melden sich unser Kredit-Team noch in der selben Woche bei dir
                    per Mail. Noch schnell geht es, wenn du uns direkt anrufst. Du findest die Telefonnummer, unter der
                    du uns erreichst, am Ende der Website. Zögere nicht, dich auch mit allen deinen Fragen an uns zu
                    wenden. Wir sind gerne für alle Auskünfte für dich da – natürlich kostenlos!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Erstgespräch!
                    </a>
                </p>

                <h3>Schritt 3: Den besten Kredit auswählen</h3>
                <p>
                    Wenn du unser Team kennengelernt hast und wir alle nötigen Details klären konnten dauert es meistens
                    nicht lange bis wir den richtigen Kredit für dich finden. Auch wenn du bereits von Banken und
                    Kreditgebern abgelehnt wurdest, stehen deine Chance mit miracl gut! Vertrau unserem Know-How – wir
                    kennen über 100 Kreditgeber in Österreich und wissen, wie man schnell und&nbsp; günstigsten an die
                    besten Kreditangebot kommt.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Jetzt zum Angebot!
                </a>
                <hr />

                <h3>Alles rund um die Immobilienfinanzierung gibt’s jetzt bei miracl</h3>
                <p>
                    miracl ist keine Bank. Wir arbeiten zu 100% unabhängig. Unsere Mission? Immobilien-Eigentum für
                    jeden und jede zugänglich zu machen. Wir verbinden dazu jahrelanges Know-How aus der Finanz-Branche
                    mit modernster Technologie. Und stellen dabei immer den Menschen in den Mittelpunkt. Probier es
                    gleich aus und melde dich jederzeit mit allen deinen Fragen bei uns.
                </p>
            </Article>
            <BreadcrumbList page={"bauKreditrechner"}></BreadcrumbList>
        </Layout>
    );
};

export default BauKreditrechner;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.dienstleistungen.bauKreditrechner"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
